import { InputHTMLAttributes, FunctionComponent } from 'react';
import { MouseEventHandler } from 'react';
import { useInputClickStream } from 'shared/components/click-stream/use-input-clickstream.hook';
import { ClickableElementProps } from 'shared/components/click-stream/clickable-element-props';

interface Props {
  ref?: (ref: HTMLInputElement) => any;
  type: string;
}

var v: MouseEventHandler<HTMLInputElement>;

export type TrackableCheckboxProps = Props & ClickableElementProps & InputHTMLAttributes<HTMLInputElement>;

export const TrackableCheckbox: FunctionComponent<TrackableCheckboxProps> = ({
                                                         ref,
                                                         onClick,
                                                         data,
                                                         disabled,
                                                         checked,
                                                         position,
                                                         type,
                                                         track,
                                                         children,
                                                         ...props}) => {
  const handleClick: any = useInputClickStream(props.id, type, track, data, checked, position, onClick);

  return (
    <input
      {...props}
      disabled={disabled}
      checked={checked}
      type={type}
      ref={ref}
      onClick={handleClick}>
      {children}
    </input>
  );
};
