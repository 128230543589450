import { useCallback, MouseEvent } from 'react';
import { InputClickedEvent } from 'shared/models/click-stream/input-clicked-event.model';
import { InputType } from 'shared/models/click-stream/input-type.model';
import { useTrackClickAdobe } from 'shared/components/analytics/user-tracking.component';

export const useInputClickStream = (
  name: string,
  inputType: string,
  track: boolean,
  context?: string,
  unchecked?: boolean,
  position?: number,
  onClick?: (event: MouseEvent<HTMLInputElement, MouseEvent> | MouseEvent<HTMLInputElement>) => void) => {

  const trackClickAdobe = useTrackClickAdobe<InputClickedEvent>();

  const trackClick = useCallback(
    (e: MouseEvent<HTMLInputElement, MouseEvent>) => {

      if (onClick) {
        onClick(e);
      }

      if (track && inputType === InputType.Checkbox) {
        const idAddition = !unchecked ? 'checked' : 'unchecked';
        trackClickAdobe(name + '-' + idAddition);
      }

    },
    [onClick, context, name, inputType, unchecked, position],
  );

  return trackClick;
};
