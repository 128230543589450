import React from 'react';

export interface Props {
  children?: string | React.ReactElement;
  ariaText?: string | React.ReactElement;
  className?: string;
}

export const Span = ({children, ariaText, className} : Props) => {
  return (
    <span className={className}>

      {/* Text visually displayed. Hidden for screen readers if ariaText is provided */}
      <span aria-hidden={Boolean(ariaText)}>
        {children}
      </span>

      {/* Text read only by screen readers, not visible to the user */}
      {Boolean(ariaText) && (
        <span className="sr-only">
          {ariaText}
        </span>
      )}

    </span>
  );
}
